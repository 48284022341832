// src/api/briefingApi.js
import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithAuth from './baseQuery';

const transformResponse = (response) => {
    return response.sort((a, b) => {
        const dateA = new Date(a.lastUpdatedAt || a.createdAt);
        const dateB = new Date(b.lastUpdatedAt || b.createdAt);
        return dateB - dateA;
    });
};

export const briefingApi = createApi({
    reducerPath: 'briefingApi',
    baseQuery: baseQueryWithAuth,
    tagTypes: ['Briefings'],
    endpoints: (builder) => ({
        fetchMyBriefings: builder.query({
            query: () => '/api/briefings/my-briefings',
            providesTags: ['Briefings'],
            transformResponse,
        }),
        fetchTodoBriefings: builder.query({
            query: () => '/api/briefings/todo',
            providesTags: ['Briefings'],
            transformResponse,
        }),
        fetchCompletedBriefings: builder.query({
            query: () => '/api/briefings/completed',
            providesTags: ['Briefings'],
            transformResponse,
        }),
        createBriefing: builder.mutation({
            query: (briefingData) => ({
                url: '/api/briefings/',
                method: 'POST',
                body: briefingData,
            }),
            invalidatesTags: ['Briefings'],
        }),
        updateBriefing: builder.mutation({
            query: ({ id, ...briefingData }) => ({
                url: `/api/briefings/${id}`,
                method: 'PUT',
                body: briefingData,
            }),
            invalidatesTags: ['Briefings'],
        }),
        deleteBriefing: builder.mutation({
            query: (id) => ({
                url: `/api/briefings/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Briefings'],
        }),
        markBriefingAsDone: builder.mutation({
            query: (id) => ({
                url: `/api/briefings/${id}/mark-as-done`,
                method: 'PUT',
            }),
            invalidatesTags: ['Briefings'],
        }),
        fetchBriefingById: builder.query({
            query: (id) => `/api/briefings/${id}/`,
            providesTags: ['Briefings'],
        }),
    }),
});

export const {
    useFetchMyBriefingsQuery,
    useFetchTodoBriefingsQuery,
    useFetchCompletedBriefingsQuery,
    useCreateBriefingMutation,
    useUpdateBriefingMutation,
    useDeleteBriefingMutation,
    useMarkBriefingAsDoneMutation,
    useFetchBriefingByIdQuery
} = briefingApi;