import React, { useMemo, useCallback, lazy, Suspense } from 'react';
import BriefingStepNavigator from './BriefingStepNavigator';
import StepComponent from './StepComponent';
import Briefing from "./Briefing";

const ChatInterface = lazy(() => import('./ChatInterface'));

const BriefingEditor = ({ briefingData, setBriefingData, activeStep, setActiveStep, questions }) => {
    const handleAnswerChange = useCallback((questionId, answer) => {
        setBriefingData(prevData => {
            const updatedQuestions = prevData.questions.map(q =>
                q.id === questionId ? { ...q, answer } : q
            );

            return new Briefing({
                ...prevData.getBriefingData(),
                questions: updatedQuestions
            });
        });
    }, [setBriefingData]);

    const filteredQuestions = useMemo(() => {
        return questions.filter(q => q.step !== "step3" || q.answer);
    }, [questions]);

    const steps = useMemo(() => [
        {
            label: 'Structured Input',
            component: StepComponent,
            props: { step: "step1", questions: filteredQuestions.filter(q => q.step === "step1") },
        },
        {
            label: 'Provide More Details',
            component: StepComponent,
            props: { step: "step2", questions: filteredQuestions.filter(q => q.step === "step2") },
        },
        {
            label: 'Final Review & Chat',
            component: ChatInterface,
            props: { step: "step3", questions: filteredQuestions.filter(q => q.step === "step3") },
        },
    ], [filteredQuestions]);

    const activeStepComponent = useMemo(() => {
        const ActiveComponent = steps[activeStep].component;
        return (
            <Suspense fallback={<div>Loading...</div>}>
                <ActiveComponent
                    {...steps[activeStep].props}
                    briefingData={briefingData}
                    handleAnswerChange={handleAnswerChange}
                />
            </Suspense>
        );
    }, [activeStep, steps, briefingData, handleAnswerChange]);

    return (
        <BriefingStepNavigator
            steps={steps}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
        >
            {activeStepComponent}
        </BriefingStepNavigator>
    );
};

export default React.memo(BriefingEditor);