import React from 'react';
import QuestionInput from './QuestionInput';

const QuestionRenderer = ({ questions, briefingData, handleAnswerChange }) => {
    if (!questions || questions.length === 0) {
        return null;
    }

    return (
        <>
            {questions.map((question) => (
                <QuestionInput
                    key={question.id}
                    question={question}
                    value={briefingData.questions.find(q => q.id === question.id)?.answer}
                    onChange={handleAnswerChange}
                />
            ))}
        </>
    );
};

export default QuestionRenderer;