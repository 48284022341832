// src/components/private/company/CompanyList.js
import React from 'react';
import { useSelector } from 'react-redux';
import {
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableFooter,
    Box,
    Pagination,
    TableCell,
    CircularProgress,
    Typography,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import CompanyItem from './CompanyItem';
import { useGetCompaniesQuery } from '../../../api/companyApi';

const CompanyList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const currentPage = parseInt(queryParams.get('page') || '1', 10);
    const limit = 25;

    const filters = useSelector(state => state.company.filters);
    const sortCriteria = useSelector(state => state.company.sortCriteria);

    const { data, error, isLoading, isFetching } = useGetCompaniesQuery(
        { page: currentPage, limit, filters, sortCriteria },
        { refetchOnMountOrArgChange: true }
    );

    const handleChangePage = (event, value) => {
        navigate(`?page=${value}`);
    };

    if (isLoading || isFetching) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="200px">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="200px">
                <Typography color="error">Error loading companies: {error.message}</Typography>
            </Box>
        );
    }

    if (!data || !data.companies || data.companies.length === 0) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="200px">
                <Typography>No companies found.</Typography>
            </Box>
        );
    }

    return (
        <TableContainer component={Paper} sx={{
            backgroundColor: '#ECEFF3',
            borderRadius: '10px',
            mt: '10px',
            mb: '10px',
        }}>
            <Table sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Beschreibung</TableCell>
                        <TableCell>Erstellt am</TableCell>
                        <TableCell>Kategorie</TableCell>
                        <TableCell>Subkategorie</TableCell>
                        <TableCell>Ähnliche Firmen</TableCell>
                        <TableCell>Aktionen</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.companies.map((company) => (
                        <TableRow key={company.id} sx={{
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            marginBottom: '5px',
                            '&:last-child': {
                                marginBottom: 0,
                            },
                        }}>
                            <CompanyItem company={company} userRole="SUPER_ADMIN" />
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={7}>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <Pagination
                                    count={data.pages}
                                    page={data.currentpage}
                                    onChange={handleChangePage}
                                    color="primary"
                                />
                            </Box>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
};

export default CompanyList;