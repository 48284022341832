import React, { useState } from 'react';
import { Box, Grid, TextField } from '@mui/material';
import BriefingPreview from './BriefingPreview';
import BriefingEditor from './BriefingEditor';
import briefingDataJson from './briefing.json';
import Briefing from './Briefing';

const BriefingComponent = () => {
    const [briefingData, setBriefingData] = useState(() => {
        const initialBriefing = new Briefing(briefingDataJson);
        if (!initialBriefing.briefingName) {
            initialBriefing.briefingName = `New Briefing - ${new Date().toLocaleString()}`;
        }
        return initialBriefing;
    });

    const [activeStep, setActiveStep] = useState(0);

    const handleBriefingNameChange = (e) => {
        const updatedBriefing = new Briefing({
            ...briefingData.getBriefingData(),
            briefingName: e.target.value
        });
        setBriefingData(updatedBriefing);
    };

    return (
        <Box sx={{ padding: '16px' }}>
            <TextField
                fullWidth
                label="Briefing Name"
                value={briefingData.briefingName || ''}
                onChange={handleBriefingNameChange}
                sx={{ marginBottom: '16px' }}
            />
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <BriefingPreview briefing={briefingData} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <BriefingEditor
                        briefingData={briefingData}
                        setBriefingData={setBriefingData}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        questions={briefingData.questions}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default BriefingComponent;