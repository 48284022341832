import React, { useState } from 'react';
import { Box } from '@mui/material';
import Header from './Header';
import Footer from './Footer';
import CompanySearchResults from './company/CompanySearchResults';

const MainLayout = ({ children }) => {
    const [searchResults, setSearchResults] = useState(null);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Header setSearchResults={setSearchResults} />
            <Box component="main" sx={{ flex: 1 }}>
                {searchResults ? <CompanySearchResults results={searchResults} /> : children}
            </Box>
            <Footer />
        </Box>
    );
};

export default MainLayout;
