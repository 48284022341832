// src/api/baseQuery.js
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getValidToken } from '../utils/tokenUtils';

const baseUrl = process.env.REACT_APP_API_URL;

const baseQueryWithAuth = async (args, api, extraOptions) => {
    const token = await getValidToken();

    const baseQuery = fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers) => {
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    });

    const result = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
        console.error('Authentication failed. Please log in again.');
    }

    return result;
};

export default baseQueryWithAuth;