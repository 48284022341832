// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import { userApi } from './api/userApi';
import { companyApi } from "./api/companyApi";
import { briefingApi } from "./api/briefingApi";
import companyReducer from './redux/slices/companySlice';
import { setupListeners } from '@reduxjs/toolkit/query';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // default is localStorage for web

const persistConfig = {
    key: 'root',
    storage,
};

const persistedUserReducer = persistReducer(persistConfig, userApi.reducer);


const store = configureStore({
    reducer: {
        [userApi.reducerPath]: persistedUserReducer, // Persisted user reducer
        [companyApi.reducerPath]: companyApi.reducer,
        [briefingApi.reducerPath]: briefingApi.reducer,
        company: companyReducer,
        // Wenn Sie einen briefingSlice erstellen, können Sie ihn hier hinzufügen:
        // briefing: briefingReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            userApi.middleware,
            companyApi.middleware,
            briefingApi.middleware
        ),
});

// Optional: Setup listeners für RTK-Query-Revalidierung
setupListeners(store.dispatch);

export const persistor = persistStore(store); // Create a persistor


export default store;