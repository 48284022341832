// src/components/private/company/CompanyOverviewLayout.js
import React, { useState, useCallback } from 'react';
import { Box } from '@mui/material';
import Grid2 from '@mui/material/Grid2';
import CompanyList from './CompanyList';
import Sidebar from './Sidebar';
import SearchBar from "./SearchBar";

const CompanyOverviewLayout = () => {
    const [filters, setFilters] = useState({ dtx: false, moonshot: false, category: '' });
    const [sortCriteria, setSortCriteria] = useState({
        sort: 'created_at',
        sort_order: 'desc',
        sort_type: 'number'
    });

    const handleApplyFilters = useCallback((dtx, moonshot, category) => {
        setFilters({ dtx, moonshot, category });
    }, []);

    const handleSortChange = useCallback((sort) => {
        setSortCriteria(sort);
    }, []);

    return (
        <Box sx={{ display: 'flex', minHeight: '100vh', flexDirection: 'column', padding: '16px' }}>
            <Grid2 container spacing={2} alignItems="center">
                <Grid2 xs={12} sm={6} md={4}>
                    <SearchBar />
                </Grid2>
                <Grid2 xs={12} sm={6} md={8}>
                    <Sidebar
                        dtx={filters.dtx}
                        moonshot={filters.moonshot}
                        category={filters.category}
                        sort={sortCriteria}
                        onApply={handleApplyFilters}
                        onSortChange={handleSortChange}
                    />
                </Grid2>
            </Grid2>
            <Grid2 container spacing={2} sx={{ marginTop: '16px' }}>
                <Grid2 xs={12}>
                    <CompanyList
                        filters={filters}
                        sortCriteria={sortCriteria}
                    />
                </Grid2>
            </Grid2>
        </Box>
    );
};

export default CompanyOverviewLayout;