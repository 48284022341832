// src/components/private/briefing/BriefingCard.js
import React from 'react';
import { Box, Typography, Button, IconButton } from '@mui/material';
import { Delete, Done } from '@mui/icons-material'; // Icons for delete and mark as done
import { useSelector } from 'react-redux';
import { selectCurrentUserRoles } from '../../../redux/selectors';

const BriefingCard = ({ briefing, handleEditBriefing, handleDeleteBriefing, handleMarkAsDone, isCompleted }) => {
    const roles = useSelector(selectCurrentUserRoles);

    return (
        <Box
            sx={{
                padding: '16px',
                backgroundColor: isCompleted ? 'success.light' : 'primary.main', // Different background for completed briefings
                color: 'white',
                borderRadius: '8px',
                position: 'relative',
            }}
        >
            {/* Delete Icon */}
            <IconButton
                sx={{ position: 'absolute', top: 8, right: 8, color: 'white' }}
                onClick={() => handleDeleteBriefing(briefing.id)}
            >
                <Delete />
            </IconButton>

            {/* Mark as Done Icon (only if not completed) */}
            {!isCompleted && (roles.includes('ADMIN') || roles.includes('SUPER_ADMIN') || roles.includes('BIZ_DEV')) && (
                <IconButton
                    sx={{ position: 'absolute', top: 8, right: 40, color: 'white' }}
                    onClick={() => handleMarkAsDone(briefing.id)}
                >
                    <Done />
                </IconButton>
            )}

            <Typography variant="body1">{briefing.name}</Typography>
            <Typography variant="body2">Created on {new Date(briefing.createdAt).toLocaleDateString()}</Typography>
            <Typography variant="body2">Created by {briefing.createdBy.email}</Typography>
            {isCompleted ? (
                <Typography variant="body2">Completed on {new Date(briefing.completedAt).toLocaleDateString()}</Typography>
            ) : (
                <Typography variant="body2">Status: {briefing.status}</Typography>
            )}
            <Button variant="contained" color="secondary" onClick={() => handleEditBriefing(briefing.id)} sx={{ marginTop: '8px' }}>
                View Briefing
            </Button>
        </Box>
    );
};

export default BriefingCard;