// src/components/Footer.js
import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer = () => {
    return (
        <Box component="footer" sx={{ p: 2, backgroundColor: '#1A2A6C', color: 'white', textAlign: 'center' }}>
            <Typography variant="body1">© 2024 RoX Health GmbH. All Rights Reserved.</Typography>
        </Box>
    );
};

export default Footer;
