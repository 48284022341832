// src/components/private/company/Sort.js
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Select, MenuItem } from '@mui/material';
import { setSortCriteria } from '../../../redux/slices/companySlice';

const sortOptions = [
    { key: 'created_at_asc', display: 'Created Date (Oldest first)', sort: 'created_at', sort_order: 'asc', sort_type: 'number' },
    { key: 'created_at_desc', display: 'Created Date (Newest first)', sort: 'created_at', sort_order: 'desc', sort_type: 'number' },
    { key: 'name_asc', display: 'Name (A-Z)', sort: 'Company_name_lowercase', sort_order: 'asc', sort_type: 'text' },
    { key: 'name_desc', display: 'Name (Z-A)', sort: 'Company_name_lowercase', sort_order: 'desc', sort_type: 'text' }
];

const Sort = () => {
    const dispatch = useDispatch();
    const sortCriteria = useSelector(state => state.company.sortCriteria);

    const handleSortChange = (event) => {
        const selectedSortOption = sortOptions.find(option => option.key === event.target.value);
        dispatch(setSortCriteria(selectedSortOption));
    };

    return (
        <Select
            variant="standard"
            value={sortCriteria.key || sortOptions[0].key}
            onChange={handleSortChange}
            size="small"
            displayEmpty
        >
            {sortOptions.map(option => (
                <MenuItem key={option.key} value={option.key}>{option.display}</MenuItem>
            ))}
        </Select>
    );
};

export default Sort;