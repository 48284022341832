import React from 'react';
import { Box } from '@mui/material';
import QuestionRenderer from "./QuestionRenderer";

const StepComponent = ({ step, questions, briefingData, handleAnswerChange }) => {
    const filteredQuestions = questions.filter(question => question.step === step);


    return (
        <Box>
            <QuestionRenderer
                questions={filteredQuestions}
                briefingData={briefingData}
                handleAnswerChange={handleAnswerChange}
            />
        </Box>
    );
};

export default React.memo(StepComponent);