// src/components/ProfilePage.js
import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { useFetchCurrentUserQuery } from '../../../api/userApi';
import { useSelector } from 'react-redux';
import { selectCurrentUserRoles } from '../../../redux/selectors';

const ProfilePage = () => {
    const { data: currentUser, isLoading } = useFetchCurrentUserQuery();
    const roles = useSelector(selectCurrentUserRoles);

    if (isLoading) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <Box sx={{ p: 3 }}>
            <Paper sx={{ p: 2 }}>
                <Typography variant="h4" gutterBottom>
                    User Details
                </Typography>
                <Typography variant="body1"><strong>ID:</strong> {currentUser.id}</Typography>
                <Typography variant="body1"><strong>UID:</strong> {currentUser.uid}</Typography>
                <Typography variant="body1"><strong>Email:</strong> {currentUser.email}</Typography>
                <Typography variant="body1"><strong>Roles:</strong> {roles.join(', ')}</Typography>

                {/* Conditionally render based on roles */}
                {roles.includes('SUPER_ADMIN') && (
                    <Typography variant="body1"><strong>Admin Access:</strong> Granted</Typography>
                )}
            </Paper>
        </Box>
    );
};

export default ProfilePage;