// src/components/private/company/Filter.js
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, FormControlLabel, Checkbox, Select, MenuItem, Button } from '@mui/material';
import { setFilters, clearFilters } from '../../../redux/slices/companySlice';
import { useAlert } from '../../Alert';

const Filter = () => {
    const dispatch = useDispatch();
    const { dtx, moonshot, category } = useSelector(state => state.company.filters);
    const showAlert = useAlert();

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        dispatch(setFilters({ [name]: checked }));
    };

    const handleSelectChange = (event) => {
        dispatch(setFilters({ category: event.target.value }));
    };

    const handleClear = () => {
        dispatch(clearFilters());
        showAlert('Filters cleared', 'info');
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <FormControlLabel
                control={<Checkbox checked={dtx} onChange={handleCheckboxChange} name="dtx" />}
                label="DTX"
            />
            <FormControlLabel
                control={<Checkbox checked={moonshot} onChange={handleCheckboxChange} name="moonshot" />}
                label="Moonshot"
            />
            <Select
                variant="standard"
                value={category}
                onChange={handleSelectChange}
                size="small"
                displayEmpty
                sx={{ minWidth: 200 }}
            >
                <MenuItem value="">
                    <em>Choose category</em>
                </MenuItem>
                <MenuItem value="8">Cardiovascular Disease</MenuItem>
            </Select>
            <Button variant="outlined" color="primary" size="small" onClick={handleClear}>
                Clear
            </Button>
        </Box>
    );
};

export default Filter;