import { v4 as uuidv4 } from 'uuid';


class Briefing {
    constructor(briefingData = {}) {
        this.briefingId = briefingData.briefingId || uuidv4();
        this.briefingName = briefingData.briefingName || `New Briefing - ${new Date().toLocaleString()}`;
        this.questions = briefingData.questions || [];
    }

    setAnswer(questionId, answer) {
        const question = this.questions.find(q => q.id === questionId);
        if (question) {
            question.answer = answer;
        }
    }

    getBriefingData() {
        return {
            briefingId: this.briefingId,
            briefingName: this.briefingName,
            questions: this.questions,
        };
    }
}

export default Briefing;
