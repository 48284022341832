// src/utils/tokenUtils.js
import { getAuth } from 'firebase/auth';
import { jwtDecode } from 'jwt-decode';

const EXPIRY_THRESHOLD = 60000; // 1 minute in milliseconds
let tokenRefreshPromise = null;

export const getToken = () => {
    return localStorage.getItem('authToken');
};

export const setToken = (token) => {
    localStorage.setItem('authToken', token);
};

export const isTokenExpired = (token) => {
    if (!token) return true;
    try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        return decodedToken.exp < currentTime + EXPIRY_THRESHOLD / 1000;
    } catch (error) {
        console.error('Error decoding token:', error);
        return true;
    }
};

export const refreshToken = async () => {
    const auth = getAuth();
    try {
        const currentUser = auth.currentUser;
        if (!currentUser) {
            throw new Error('No user is currently signed in');
        }

        const newIdToken = await currentUser.getIdToken(true);
        setToken(newIdToken);
        return newIdToken;
    } catch (error) {
        console.error('Error refreshing token:', error);
        throw error;
    }
};

export const getValidToken = async () => {
    let token = getToken();
    if (isTokenExpired(token)) {
        if (!tokenRefreshPromise) {
            tokenRefreshPromise = refreshToken().finally(() => {
                tokenRefreshPromise = null;
            });
        }
        token = await tokenRefreshPromise;
    }
    return token;
};