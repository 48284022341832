import React from 'react';
import { Button, Stepper, Step, StepLabel, Box } from '@mui/material';

const BriefingStepNavigator = ({
                                   steps,
                                   activeStep,
                                   setActiveStep,
                                   children
                               }) => {
    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    return (
        <Box>
            <Stepper activeStep={activeStep}>
                {steps.map((step, index) => (
                    <Step key={step.label}>
                        <StepLabel>{step.label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Box my={2}>
                {children}
            </Box>
            <Box mt={2}>
                <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                >
                    Back
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    disabled={activeStep === steps.length - 1}
                >
                    {activeStep < steps.length - 1 && 'Next'}
                </Button>
            </Box>
        </Box>
    );
};

export default React.memo(BriefingStepNavigator);