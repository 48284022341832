// src/components/private/briefing/BriefingOverview.js
import React from 'react';
import { Box, Grid, Typography, Button, CircularProgress } from '@mui/material';
import { Assignment, Search, CheckCircle } from '@mui/icons-material'; // Icons for sections
import { useNavigate } from 'react-router-dom';
import {
    useFetchMyBriefingsQuery,
    useFetchTodoBriefingsQuery,
    useFetchCompletedBriefingsQuery,
    useDeleteBriefingMutation,
    useMarkBriefingAsDoneMutation
} from '../../../api/briefingApi';
import BriefingCard from './BriefingCard'; // Import the BriefingCard component

const BriefingOverview = () => {
    const navigate = useNavigate();

    const { data: myBriefings, isLoading: isLoadingMyBriefings } = useFetchMyBriefingsQuery();
    const { data: todoBriefings, isLoading: isLoadingTodoBriefings } = useFetchTodoBriefingsQuery();
    const { data: completedBriefings, isLoading: isLoadingCompletedBriefings } = useFetchCompletedBriefingsQuery();

    const [deleteBriefing] = useDeleteBriefingMutation();
    const [markBriefingAsDone] = useMarkBriefingAsDoneMutation();

    const handleCreateNew = () => {
        navigate('/briefing/new');
    };

    const handleEditBriefing = (briefingId) => {
        navigate(`/briefing/${briefingId}`);
    };

    const handleDeleteBriefing = (briefingId) => {
        deleteBriefing(briefingId);
    };

    const handleMarkAsDone = (briefingId) => {
        markBriefingAsDone(briefingId);
    };

    if (isLoadingMyBriefings || isLoadingTodoBriefings || isLoadingCompletedBriefings) {
        return <CircularProgress />;
    }

    return (
        <Box sx={{ padding: '16px' }}>
            {/* My Briefings Section */}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={handleCreateNew} sx={{ marginTop: '16px' }}>
                        + Create new
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">
                        <Assignment sx={{ verticalAlign: 'middle', marginRight: '8px' }} />
                        My Briefings
                    </Typography>
                    <Grid container spacing={2}>
                        {myBriefings && myBriefings.map((briefing) => (
                            <Grid item xs={12} sm={6} md={3} key={briefing.id}>
                                <BriefingCard
                                    briefing={briefing}
                                    handleEditBriefing={handleEditBriefing}
                                    handleDeleteBriefing={handleDeleteBriefing}
                                    handleMarkAsDone={handleMarkAsDone}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>

            {/* Scouting Requests Section */}
            <Grid container spacing={2} sx={{ marginTop: '32px' }}>
                <Grid item xs={12}>
                    <Typography variant="h6">
                        <Search sx={{ verticalAlign: 'middle', marginRight: '8px' }} />
                        Scouting Requests
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        {todoBriefings && todoBriefings.map((briefing) => (
                            <Grid item xs={12} sm={6} md={3} key={briefing.id}>
                                <BriefingCard
                                    briefing={briefing}
                                    handleEditBriefing={handleEditBriefing}
                                    handleDeleteBriefing={handleDeleteBriefing}
                                    handleMarkAsDone={handleMarkAsDone}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>

            {/* Completed Briefings Section */}
            <Grid container spacing={2} sx={{ marginTop: '32px' }}>
                <Grid item xs={12}>
                    <Typography variant="h6" color="success.main">
                        <CheckCircle sx={{ verticalAlign: 'middle', marginRight: '8px', color: 'success.main' }} />
                        Completed Briefings
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        {completedBriefings && completedBriefings.map((briefing) => (
                            <Grid item xs={12} sm={6} md={3} key={briefing.id}>
                                <BriefingCard
                                    briefing={briefing}
                                    handleEditBriefing={handleEditBriefing}
                                    // No "mark as done" for completed briefings
                                    handleDeleteBriefing={handleDeleteBriefing}
                                    isCompleted
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default BriefingOverview;