// src/redux/slices/companySlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    filters: {
        dtx: false,
        moonshot: false,
        category: ''
    },
    sortCriteria: {
        sort: 'created_at',
        sort_order: 'desc',
        sort_type: 'number'
    }
};

const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        setFilters: (state, action) => {
            state.filters = { ...state.filters, ...action.payload };
        },
        clearFilters: (state) => {
            state.filters = initialState.filters;
        },
        setSortCriteria: (state, action) => {
            state.sortCriteria = action.payload;
        }
    }
});

export const { setFilters, clearFilters, setSortCriteria } = companySlice.actions;

export default companySlice.reducer;