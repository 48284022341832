import { useEffect } from 'react';

const GlobalErrorHandler = () => {
    useEffect(() => {
        // Fängt globale Fehler ab und protokolliert sie
        window.addEventListener('error', function (event) {
            console.error('Global error caught:', event.message, 'at', event.filename, 'line:', event.lineno, 'col:', event.colno);
        });

        window.addEventListener('unhandledrejection', function (event) {
            console.error('Unhandled promise rejection:', event.reason);
        });

        // Setzt eine erweiterte Version des ResizeObserver ein, um detaillierteres Logging zu ermöglichen
        const originalResizeObserver = window.ResizeObserver;

        window.ResizeObserver = class extends originalResizeObserver {
            constructor(callback) {
                super((entries, observer) => {
                    try {
                        callback(entries, observer);
                    } catch (error) {
                        console.error('ResizeObserver callback error extended:', error);
                    }
                });
            }
        };

        return () => {
            // Cleanup event listeners if needed
            window.removeEventListener('error', null);
            window.removeEventListener('unhandledrejection', null);
        };
    }, []);

    return null; // Diese Komponente rendert nichts, sie dient nur zur Erfassung von Fehlern
};

export default GlobalErrorHandler;
