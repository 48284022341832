import { createSelector } from '@reduxjs/toolkit';

export const selectCompanyState = (state) => state.companyApi.queries;

export const selectTotalItems = createSelector(
    [selectCompanyState],
    (companyState) => {
        const queryKey = Object.keys(companyState).find(key => key.startsWith('getCompanies'));
        return queryKey ? companyState[queryKey].data?.itemstotal || 0 : 0;
    }
);

export const selectBriefingState = (state) => state.briefingApi.queries;

export const selectBriefingById = createSelector(
    [selectBriefingState, (_, briefingId) => briefingId],
    (briefingState, briefingId) => {
        const queryKey = Object.keys(briefingState).find(key => key.startsWith('fetchBriefings'));
        if (queryKey && briefingState[queryKey].data) {
            return briefingState[queryKey].data.find(briefing => briefing.id === briefingId);
        }
        return null;
    }
);

// Added selector for user roles
export const selectUserState = (state) => state.userApi.queries;

export const selectCurrentUserRoles = createSelector(
    [selectUserState],
    (userState) => {
        const queryKey = Object.keys(userState).find(key => key.startsWith('fetchCurrentUser'));
        return queryKey ? userState[queryKey].data?.roles || [] : [];
    }
);