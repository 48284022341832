// src/components/private/company/Sidebar.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import Filter from './Filter';
import Sort from './Sort';
import AddCompany from './AddCompany';
import { selectTotalItems } from '../../../redux/selectors';

const Sidebar = () => {
    const totalItems = useSelector(selectTotalItems);

    return (
        <Box component="aside"
             className="sidebar"
             sx={{
                 width: '100%',
                 p: 2,
                 display: 'flex',
                 alignItems: 'center',
                 justifyContent: 'space-between',
                 backgroundColor: '#ECEFF3',
                 borderRadius: '10px',
                 mt: '10px',
                 mb: '10px',
             }}>

            {/* Left side */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" sx={{ color: '#386FA6', mr: 2 }}>{totalItems} Firmen gefunden</Typography>
            </Box>

            {/* Right side */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Filter />
                <Sort />
                <AddCompany />
            </Box>
        </Box>
    );
};

export default Sidebar;