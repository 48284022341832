// src/components/private/briefing/BriefingDetails.js
import React from 'react';
import { Box, Grid, Typography, IconButton, CircularProgress, TextField, Tooltip } from '@mui/material';
import { Delete, Done, ContentCopy, SmartToy, LowPriority, PriorityHigh, CheckCircle } from '@mui/icons-material'; // Icons for actions and quality
import { useParams, useNavigate } from 'react-router-dom';
import { useFetchBriefingByIdQuery, useDeleteBriefingMutation, useMarkBriefingAsDoneMutation } from '../../../api/briefingApi';
import { useSelector } from 'react-redux';
import { selectCurrentUserRoles } from '../../../redux/selectors';

const BriefingDetails = () => {
    const { id } = useParams(); // Get briefing ID from the URL params
    const navigate = useNavigate();
    const roles = useSelector(selectCurrentUserRoles);

    // Fetch the briefing details by ID
    const { data: briefing, isLoading } = useFetchBriefingByIdQuery(id);

    const [deleteBriefing] = useDeleteBriefingMutation();
    const [markBriefingAsDone] = useMarkBriefingAsDoneMutation();

    // Handle deletion
    const handleDeleteBriefing = () => {
        deleteBriefing(id).then(() => navigate('/briefings')); // Navigate back after deletion
    };

    // Handle marking as done
    const handleMarkAsDone = () => {
        markBriefingAsDone(id);
    };

    // Copy the prompt content to clipboard
    const handleCopyPrompt = () => {
        navigator.clipboard.writeText(briefing.prompt || '');
    };

    if (isLoading) {
        return <CircularProgress />;
    }

    if (!briefing) {
        return <Typography color="error">Briefing not found.</Typography>;
    }

    // Function to determine the icon and color for answer quality
    const getQualityIcon = (quality) => {
        switch (quality) {
            case 'low':
                return <LowPriority sx={{ color: 'red' }} />;
            case 'medium':
                return <PriorityHigh sx={{ color: 'orange' }} />;
            case 'high':
                return <CheckCircle sx={{ color: 'green' }} />;
            default:
                return null;
        }
    };

    return (
        <Box sx={{ padding: '16px', position: 'relative' }}>
            {/* Header Section */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>{briefing.name}</Typography>

                {/* Actions (Delete and Mark as Done) */}
                <Box>
                    {/* Delete Icon */}
                    <IconButton sx={{ color: 'error.main' }} onClick={handleDeleteBriefing}>
                        <Delete />
                    </IconButton>

                    {/* Mark as Done Icon (only for authorized users) */}
                    {(roles.includes('ADMIN') || roles.includes('SUPER_ADMIN') || roles.includes('BIZ_DEV')) && (
                        <IconButton sx={{ color: 'success.main' }} onClick={handleMarkAsDone}>
                            <Done />
                        </IconButton>
                    )}
                </Box>
            </Box>

            <Grid container spacing={4} sx={{ marginTop: '24px' }}>
                {/* Questions and Answers Section */}
                <Grid item xs={12} md={6}>
                    <Typography variant="h5" sx={{ marginBottom: '16px', fontWeight: 'bold' }}>Questions and Answers</Typography>
                    {briefing.document?.questions.map((q) => (
                        <Box key={q.id} sx={{ marginBottom: '16px', padding: '16px', backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: 1 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                    {q.question}
                                </Typography>

                                {q.ai_feedback_needed && (
                                    <Tooltip title="This question was answered with AI assistance" placement="left">
                                        <SmartToy sx={{ color: 'blue' }} />
                                    </Tooltip>
                                )}
                            </Box>

                            <Typography variant="body2" sx={{ marginTop: '8px', fontSize: '0.95rem', color: '#555' }}>
                                {q.answer.length > 0 ? q.answer.join(', ') : 'No answer provided.'}
                            </Typography>

                            {/* Only show answer quality if AI feedback is needed */}
                            {q.ai_feedback_needed && (
                                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '12px' }}>
                                    {getQualityIcon(q.rating.answerQuality)}
                                    <Typography
                                        variant="body2"
                                        sx={{ marginLeft: '8px', fontWeight: 'bold', color: getQualityIcon(q.rating.answerQuality)?.props.sx.color }}
                                    >
                                        Answer Quality: {q.rating.answerQuality.toUpperCase()}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    ))}
                </Grid>

                {/* Prompt Section */}
                <Grid item xs={12} md={6}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant="h5" sx={{ marginBottom: '16px', fontWeight: 'bold' }}>StartUs Prompt</Typography>
                        <Tooltip title="Copy to clipboard" placement="left">
                            <IconButton onClick={handleCopyPrompt}>
                                <ContentCopy />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <TextField
                        multiline
                        fullWidth
                        rows={16}
                        value={briefing.prompt || ''}
                        variant="outlined"
                        InputProps={{
                            readOnly: true, // Make it read-only
                        }}
                    />
                </Grid>
            </Grid>

            {/* Status Information placed at the bottom right */}
            <Box
                sx={{
                    position: 'absolute',
                    bottom: '16px',
                    right: '16px',
                    textAlign: 'right',
                    fontSize: '0.9rem',
                }}
            >
                <Typography variant="body2"><strong>Status:</strong> {briefing.status}</Typography>
                <Typography variant="body2"><strong>Created by:</strong> {briefing.createdBy.email}</Typography>
                <Typography variant="body2"><strong>Created on:</strong> {new Date(briefing.createdAt).toLocaleDateString()}</Typography>
            </Box>
        </Box>
    );
};

export default BriefingDetails;