import React from 'react';
import { Paper, Typography, Box, Chip } from '@mui/material';

const BriefingPreview = ({ briefing }) => {
    const getAnswerText = (question) => {
        if (!question.answer) return 'No answer provided';

        if (question.type === 'multiselect' || question.type === 'hierarchical-multiselect') {
            const findOptionText = (options, id) => {
                for (let option of options) {
                    if (option.id === id) return option.text;
                    if (option.subregions) {
                        const subText = findOptionText(option.subregions, id);
                        if (subText) return subText;
                    }
                }
                return id;
            };

            return question.answer.map(id => findOptionText(question.metadata.options, id)).join(', ');
        }

        if (question.type === 'text' && question.metadata?.keyword) {
            return (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {question.answer.map((keyword, index) => (
                        <Chip key={index} label={keyword} size="small" />
                    ))}
                </Box>
            );
        }

        return question.answer;
    };

    return (
        <Paper sx={{ padding: '16px', backgroundColor: '#f5f5f5', height: '100%', overflowY: 'auto' }}>
            <Typography variant="h6">Briefing Preview</Typography>
            <Box>
                {briefing.questions.filter(question => question.step !== 'step3').map((question) => (
                    <Box key={question.id} sx={{ marginBottom: '12px' }}>
                        <Typography variant="body1">
                            <strong>{question.question}:</strong>
                        </Typography>
                        <Typography variant="body2" sx={{ marginTop: '4px' }}>
                            {getAnswerText(question)}
                        </Typography>
                    </Box>
                ))}
            </Box>
        </Paper>
    );
};

export default BriefingPreview;