// src/api/companyApi.js
import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithReauth from './baseQuery';

export const companyApi = createApi({
    reducerPath: 'companyApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Companies'],
    endpoints: (builder) => ({
        getCompanies: builder.query({
            query: ({ page, limit, filters, sortCriteria }) => ({
                url: '/proxy/api:Y3T-Nl6Y/multiparameter_searchcompanies',
                method: 'POST',
                body: {
                    page,
                    limit,
                    ...filters,
                    disease_id: filters.category,
                    sort_by: sortCriteria.sort,
                    sort_order: sortCriteria.sort_order,
                    sort_type: sortCriteria.sort_type
                },
            }),
            providesTags: ['Companies'],
        }),
        deleteCompany: builder.mutation({
            query: (id) => ({
                url: `/api/companies/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Companies'],
        }),
    }),
});

export const { useGetCompaniesQuery, useDeleteCompanyMutation } = companyApi;