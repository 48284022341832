import React, { useState } from 'react';
import { Box, Typography, Select, MenuItem, Button, CircularProgress } from '@mui/material';
import { useFetchUsersQuery, useAssignRoleMutation } from '../../../api/userApi'; // Importiere die RTK Query Hooks

const availableRoles = ['USER', 'ADMIN', 'SUPER_ADMIN', 'BIZ_DEV'];

const UserManagement = () => {
    const { data: users = [], isLoading, error } = useFetchUsersQuery();
    const [assignRole] = useAssignRoleMutation();
    const [roleChanges, setRoleChanges] = useState({});

    const handleSelectChange = (uid, newRoles) => {
        setRoleChanges((prev) => ({
            ...prev,
            [uid]: newRoles,
        }));
    };

    const handleSaveChanges = async (uuid) => {
        const newRoles = roleChanges[uuid] || [];
        try {
            await assignRole({ uuid, roles: newRoles }).unwrap();
            alert('Roles updated successfully');
        } catch (error) {
            alert('Failed to assign roles');
        }
    };

    if (isLoading) return <CircularProgress />;
    if (error) return <Typography color="error">Failed to load users</Typography>;

    return (
        <Box>
            <Typography variant="h4" gutterBottom>User Management</Typography>
            {users.map((user) => (
                <Box key={user.id} sx={{ padding: 2, borderBottom: '1px solid #ccc', marginBottom: '16px' }}>
                    <Typography variant="h6">{user.email}</Typography>
                    <Typography variant="body1">UID: {user.uid}</Typography>
                    <Typography variant="body2">Current Roles: {user.roles.join(', ')}</Typography>

                    <Select
                        multiple
                        value={roleChanges[user.uid] || user.roles}
                        onChange={(event) => handleSelectChange(user.uid, event.target.value)}
                        renderValue={(selected) => selected.join(', ')}
                        sx={{ width: 300, marginTop: 1 }}
                    >
                        {availableRoles.map((role) => (
                            <MenuItem key={role} value={role}>
                                {role}
                            </MenuItem>
                        ))}
                    </Select>

                    <Button
                        variant="contained"
                        onClick={() => handleSaveChanges(user.uid)}
                        sx={{ marginTop: 1 }}
                    >
                        Save Changes
                    </Button>
                </Box>
            ))}
        </Box>
    );
};

export default UserManagement;
